import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';
import ContactForm from '../components/Form/ContactForm';
import SocialShare from '../components/Shop/SocialShare';

const ContactPage = (props) => {
  const {
    data: { bgContact },
  } = props;

  return (
    <BodyClassName className="body-light page-contact">
      <DefaultLayoutComponent title="Contact">
        <div className="site-main">
          <BackgroundImage
            className="page-banner bgImage"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Contact</h3>
              </div>
              <div className="section bg-light pd-t-0 contact">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="breadcrumb pd-x-sm"></div>
                  <div className="contact-block columns d-flex pd-x-sm">
                    <div className="cols contact-form pd-x-0">
                      <h4>Drop us a line</h4>
                      <ContactForm />
                    </div>
                    <div className="cols contact-map pd-x-0">
                      <div className="map-contact">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4799144.12013076!2d-8.946359!3d54.0323753!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sru!4v1606954783301!5m2!1sen!2sru"
                          width="600"
                          height="450"
                          frameBorder="0"
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        ></iframe>
                      </div>
                      <SocialShare title="Contact Us" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default ContactPage;
